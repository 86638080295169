<template>
  <div class="diwa">
    <div class="biaoti mt20">
      <span class="el-icon-caret-bottom"></span>
      工作机制
    </div>
    <div class="jieshao mt20">
      通过积水探测器超声波接口监测地面积水深度，并通过内置LORA通信方式上报智能网关，智能网关发送云服务器，为用户指挥决策提供数据支持，提升城市水文监测能力。同时可通过LoRa通信方式传送至附近智能网关进行现场预警指示。
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      主要功能
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水位采集</div>
      <div class="li-text">有积水时，根据设置的采集周期定时采集水位</div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据传输</div>
      <div class="li-text">通过LORA进行数据发送</div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">积水探测</div>
      <div class="li-text">智能传感器检测积水状态，没积水不采集</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智能采集频度</div>
      <div class="li-text">多个采集频度自动控制，水位越高采集越频繁</div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据自动补发</div>
      <div class="li-text">保障数据的完整性</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据存储</div>
      <div class="li-text">可保存积水数据，掉电数据不丢失</div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">远程配置</div>
      <div class="li-text">运行参数远程配置</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">休眠模式</div>
      <div class="li-text">支持低功耗休眠工作模式</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">支持与平台联动，当水位到达阈值，可发出声光报警</div>
    </div>
    <div class="ct mt30">低洼积水监测智能传感器</div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/119.png" alt="" />
      <img src="../../../../assets/imgs/chanping/shebei/120.png" alt="" />
    </div>

    <div class="ct mt30">安装现场图</div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/121.png" alt="" />
      <img src="../../../../assets/imgs/chanping/shebei/122.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.diwa {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
  .ct {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    text-align: center;
    color: #666666;
  }
}
</style>