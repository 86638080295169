<template>
  <div class="fandou">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/1.png" alt="" />
    </div>
    <div class="jieshao">
      本仪器为降水量测量仪表，翻斗部件支承系统制造精良，摩阻力矩小，因而翻斗部件翻转灵敏，性能稳定，工作可靠。仪器的水路通道部件引水漏斗、翻斗及排水漏斗用进口优质透明材料制作，使仪器动态工作过程观测更为一目了然。仪器性能符合国家标准GB/T11832-2002《翻斗式雨量计》要求，并取得全国工业产品生产许可证。
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="text">
      用以测量自然界降雨量，同时将降雨量转换为以开关量形式表示的数字信息量输出，以满足信息传输、处理、记录和显示等的需要。可用于气象台(站)、水文站、农林、国防、野外测报站等有关部门，配合我公司自主研发生产的RTU来测量降水量、降水强度、降水时间等。可为防洪、供水调度、电站水库水情管理提供原始数据。
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      技术参数
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">承水口径</div>
      <div class="li-text">Ф200±0.6mm</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">承雨口尺寸</div>
      <div class="li-text">φ20000.60mm； 刃口锐角：40o～45o</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">分辨力</div>
      <div class="li-text">0.5mm</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">测量准确度</div>
      <div class="li-text">≤±3%（室内人工降水、以仪器自身排水量为准）</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">雨强范围</div>
      <div class="li-text">0.01mm～4mm/min（允许通过最大雨强8mm/min）</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">发讯方式</div>
      <div class="li-text">双触点通断信号输出</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">工作环境</div>
      <div class="li-text">环境温度：0～50℃，相对湿度； &lt;95%(40℃)</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">尺寸重量</div>
      <div class="li-text">2.5kg</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">体积</div>
      <div class="li-text">φ216mm×460mm</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.fandou {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>