<template>
  <div class="jianyi">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/2.png" alt="" />
    </div>
    <div class="jieshao">
      本仪器的翻斗轴套为一体化旋转式定位结构，使翻斗的装、拆更加方便，也无需再调整两个轴套之间的距离，
      给现场安装带来了方便；翻斗为三维流线型设计，并设计有下垂式弧面导流尖，其造型美观流畅、翻水性能更好且易清洗维护；引水漏斗与翻斗支架为紧配合安装，一般情况下不必取下引水漏斗。
      <br />
      仪器的水路通道部件引水漏斗、翻斗及排水漏斗用进口优质透明材料制作，使仪器动态工作过程观测更为一目了然。
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      技术参数
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">翻斗式雨量计分辨率</div>
      <div class="li-text">0.5mm</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">显示方式及内容</div>
      <div class="li-text">
        液晶双排汉子显示屏显示日期时间、昨日雨量、今日雨量、报警级别
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">存储容量</div>
      <div class="li-text">
        固态存储器可记录8-10年降雨过程信息（每年3500mm降雨量），可用专业计算机软件通过USB通信口读出历史雨量信息
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">时钟功能</div>
      <div class="li-text">可显示日期、时间</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">报警方式</div>
      <div class="li-text">
        多时段，每时段降雨量三级声光语音报警+电源电压低语音报警
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">报警音量</div>
      <div class="li-text">≥85dB</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">报警器输入、输出接口</div>
      <div class="li-text">USB及音频信号输出接口</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">供电方式</div>
      <div class="li-text">
        3节普通1#干电池或交直流转换模块（USB接口）自动切换供电
        <br />当使用3节普通1#干电池供电可连续工作半年到一年（由电池质量确定）
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">无线传输</div>
      <div class="li-text">翻斗式雨量计至报警器无线传输距离≥100m（开阔地）</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">工作环境</div>
      <div class="li-text">环境温度：-10℃-50℃，相对湿度： &lt;95%（40℃）</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">报警器尺寸、重量</div>
      <div class="li-text">240×170×60mm ≤1200g（不含电池）</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.jianyi {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>