<template>
  <div class="rtu">
    <div class="jieshao">
      遥测终端机主要应用于水文水资源实时监控系统的数据采集环节，采用超低功耗设计，特别适合使用太阳能供电工作的场合。提供多个通道的符合工业标准的模拟接口和数字接口，可以外接包括雨量计、水位计、水压计、流量计、水质仪器等多种类型的仪表和传感器。并可通过GPRS或CDMA网络将数据发送至中心机房的服务器中，通信协议遵循水利部SL427-2008标准。
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">HK.R01超轻量级雨量远程终端</div>
      <div class="li-text">
        该设备是为单雨量站专门设计的超轻量级设备,在线功耗低至4mA
        <br />采用业界先进的铁电存储器，具有极长的使用寿命
        <br />具有一路雨量计接口 <br />提供周期雨量数据上报，定时平安报
        <br />支持GPRS数据通信，具有电话唤醒和短信查询功能
        <br />支持现场和远程参数设置，支持配置文件导入
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">HK.R01轻量级远程终端</div>
      <div class="li-text">
        该设备自带长效锂电池，自带太阳能板，采用航空插座，高度防水防尘，安装简便，除了支架无需其他外部设置。稳定，可靠，能适宜各种恶劣环境
        <br />
        带有SDI-12一路，雨量一路
        <br />支持GPRS数据通信，具有电话唤醒和短信查询功能
        <br />支持现场和远程参数设置，支持配置文件导入
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">HK.R01远程终端简配版</div>
      <div class="li-text">
        该设备是为中型观察站专门设计设备
        <br />
        具有8M超大非易失Flash，可以存储超过半年的数据
        <br />提供一路雨量脉冲接口，一路模拟信号量接入
        <br />提供一路12V电源控制，一路24V电源控制
        <br />支持GPRS/CDMA数据通信，具有电话唤醒和短信查询功能
        <br />支持GSM/GPRS/CDMA,北斗卫星，三通道冗余通信备份
        <br />支持GPRS/CDMA中心授时，北斗卫星授时
        <br />支持现场和远程参数设置，支持配置文件导入
        <br />设备内部配置精密温度传感器，支持电源监测和温度监测
        <br />支持模块故障和传感器故障检测 <br />多种上报方式，灵活配置
        <br />支持滤波算法，可有效过滤异常数据
        <br />支持实时在线，待机，掉电工作模式，适合不同的供电环境
        <br />支持远程升级功能 <br />支持远程配置，远程调试
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">HK.R01远程终端高配置版</div>
      <div class="li-text">
        该设备是为中型观察站专门设计设备
        <br />具有8M超大非易失Flash，可以存储超过半年的数据
        <br />提供2路雨量脉冲接口，2路模拟信号量接入，1路485接口，1路SDI接口
        <br />提供一路12V电源控制，一路24V电源控制
        <br />支持GPRS/CDMA数据通信，具有电话唤醒和短信查询功能
        <br />支持GSM/GPRS/CDMA，北斗卫星，三通道冗余通信备份
        <br />支持GPRS/CDMA中心授时，北斗卫星授时
        <br />支持现场和远程参数设置，支持配置文件导入
        <br />设备内部配置精密温度传感器，支持电源监测和温度监测
        <br />支持模块故障和传感器故障检测 <br />多种上报方式，灵活配置
        <br />支持滤波算法，可有效过滤异常数据
        <br />支持实时在线，待机，掉电工作模式，适合不同的供电环境
        <br />支持远程升级功能 <br />支持远程配置，远程调试
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">HK.R01远程终端加密版</div>
      <div class="li-text">
        支持国标加密 <br />拥有中国加密局认证
        <br />该设备是为中型观察站专门设计设备
        <br />具有8M超大非易失Flash，可以存储超过半年的数据
        <br />提供2路雨量脉冲接口，2路模拟信号量接入，1路485接口，1路SDI接口
        <br />提供一路12V电源控制，一路24V电源控制
        <br />支持GPRS/CDMA数据通信，具有电话唤醒和短信查询功能
        <br />支持GSM/GPRS/CDMA,北斗卫星，三通道冗余通信备份
        <br />支持GPRS/CDMA中心授时，北斗卫星授时
        <br />支持现场和远程参数设置，支持配置文件导入
        <br />设备内部配置精密温度传感器，支持电源监测和温度监测
        <br />支持模块故障和传感器故障检测 <br />多种上报方式，灵活配置
        <br />支持滤波算法，可有效过滤异常数据
        <br />支持实时在线，待机，掉电工作模式，适合不同的供电环境
        <br />支持远程升级功能 <br />支持远程配置，远程调试
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.rtu {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>