<template>
  <div class="wlw">
    <div class="biaoti mt20">
      <span class="el-icon-caret-bottom"></span>
      主要作用
    </div>
    <div class="jieshao mt20">
      智慧物联安全终端能为项目前端资产提供资产监控、安全加固、能耗监察、故障处置四大能力，所有监管数据和报警信息通过视频光纤网络或4G/5G网络上传至运营监控中心的综合监管平台。
    </div>
    <!-- <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/117.png" alt="" />
    </div> -->

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      设备参数
    </div>

    <div class="jieshao mt10">
      采用冷轧钢板材质外壳，钣金件做发泡胶圈防水 <br />
      低功耗嵌入式芯片 <br />
      具备AC220V输出/输入、DC48V输入、DC12V输出、RS485等多种上端接口，采用防水航空插头
      <br />
      具备1路RJ45千兆电口输入（SFP光纤接口可选）、8路RJ45百兆电口输出、DI/DO接口、电流互感器接口、4G和GPS天线接口
      <br />
      预留SMA座，可扩展模块，带防水帽 <br />
      工作温度-30~60℃、IP66防护等级
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/118.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.wlw {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>